@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.background {
  position: relative;
  z-index: 5;
  overflow: hidden;
  border-radius: 0 0 2.5rem 2.5rem;

  @include screen-size('medium') {
    overflow: hidden;
    border-radius: 0;
  }

  &.is-vans {
    @include pink-to-maroon-background;
  }

  &.is-cars {
    @include yellow-to-pink-to-maroon-to-purple-to-maroon-background;
  }
}

.top {
  position: absolute;
  z-index: 0;
  top: -11rem;
  left: 0;
  display: block;
  width: 215%;
  height: 100%;

  @include screen-size('custom', 380px) {
    top: -12rem;
    width: 200%;
  }

  @include screen-size('custom', 535px) {
    top: -13rem;
    width: 210%;
  }

  @include screen-size('extra-small') {
    top: -13.5rem;
  }

  @include screen-size('custom', 700px) {
    top: -13.5rem;
  }

  @include screen-size('custom', 730px) {
    top: -14rem;
  }

  @include screen-size('iphone-14') {
    top: -11.875rem;
  }

  @include screen-size('small') {
    top: -17.8rem;
    width: 210%;
  }

  @include screen-size('custom', 900px) {
    top: -19rem;
    width: 210%;
  }

  @include screen-size('medium') {
    top: -14.5rem;
    width: 100%;
  }

  @include screen-size('large') {
    top: -15rem;
  }

  @include screen-size('extra-large') {
    top: -16rem;
  }

  @include screen-size('custom', 1520px) {
    top: -17rem;
  }

  @include screen-size('custom', 1650px) {
    top: -17.25rem;
  }

  @include screen-size('custom', 1750px) {
    top: -18rem;
  }

  @include screen-size('custom', 1900px) {
    top: -18.25rem;
  }

  @include screen-size('custom', 1950px) {
    top: -18rem;
  }

  @include screen-size('custom', 2125px) {
    top: -19rem;
  }

  @include screen-size('custom', 2250px) {
    top: -19.5rem;
  }
}

.full {
  position: absolute;
  z-index: -1;
  inset: 0;

  img {
    object-fit: cover;
  }
}

.bottom {
  position: absolute;
  z-index: 0;
  bottom: -17rem;
  left: -5%;
  display: none;
  width: 110%;
  height: 100%;

  img {
    object-fit: cover;
  }

  @include screen-size('medium') {
    display: block;
  }
}

.widget {
  display: grid;
  padding: 1.5rem 0 0;
  place-items: center;

  @include screen-size('medium') {
    display: none;
  }
}
