@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.main {
  @include text-xxs;
  @include text-bold;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-xxs;
  border-radius: $spacing-xxs;
  text-transform: uppercase;

  &[data-badge-type='stock-status'] {
    background: $system-black;
    color: $label-primary-dark;
  }

  &[data-badge-type='electric'] {
    background: transparentize($blue, 0.88);
    color: color-mix(in srgb, $blue 60%, $system-black 40%);
  }

  &[data-badge-type='standard'] {
    background: $fill-inactive-light;
    color: $label-text;
  }

  &[data-badge-type='loco-day'] {
    background: linear-gradient(135deg, #FFD700 0.31%, #FF2D55 99.68%);
    color: $white;
  }
}
