@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.reverse-image {
  display: grid;
  transform: rotateY(180deg);

  @include screen-size('medium') {
    width: 100%;
    height: 100%;
  }

  span,
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.padded-image {
  display: grid;
  width: 100%;
  height: 100%;
  padding: $spacing-sm;

  img {
    object-fit: contain;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  @include screen-size('small') {
    &:hover {
      .arrows {
        opacity: 1;
      }
    }
  }

  .dots {
    position: absolute;
    right: 50%;
    bottom: 1.25rem;
    display: flex;
    width: auto;
    height: min-content;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
    place-content: center;
    transform: translateX(50%);
    transition: width 100ms ease-in-out;

    &[data-alignment='right'] {
      right: 1.5rem;
      bottom: 0;
      transform: none;
    }

    .dot {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      aspect-ratio: 1/1;
      background-color: $label-inactive-light;
      content: '';
      transition:
        background-color 100ms ease-in-out,
        margin 60ms ease-in-out 40ms,
        width 60ms ease-in-out 40ms,
        height 60ms ease-in-out 40ms;

      &.close {
        width: 0.375rem;
        height: 0.375rem;
        margin: 0 0.375rem;
      }

      &.far {
        width: 0.25rem;
        height: 0.25rem;
        margin: 0 0.0675rem;
      }

      &.too-far {
        width: 0;
        height: 0;
      }

      &.active {
        margin: 0 0.375rem;
        background-color: $label-text;
      }
    }

    .far + .far {
      display: none;
    }
  }

  .arrows {
    position: absolute;
    top: calc(50%);
    display: flex;
    width: 100%;
    height: min-content;
    justify-content: space-between;
    padding: 0 0.75rem;
    margin: 0;
    gap: 0.5rem;
    list-style: none;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50%);
    transition: opacity 100ms ease-in-out;

    button {
      pointer-events: all;
    }

    &[data-navigation-position='start'] button:first-of-type {
      appearance: none;
      opacity: 0;
      pointer-events: none;
    }

    &[data-navigation-position='end'] button:last-of-type {
      appearance: none;
      opacity: 0;
      pointer-events: none;
    }
  }

  &[data-type='SPEC'] {
    [data-index='1'],
    [data-index='2'] {
      scale: 0.5;
    }

    @include screen-size('medium') {
      .arrows {
        width: calc(100% + 2.5rem);
        opacity: 1;
      }
    }
  }
}

.carousel {
  display: flex;
  height: 100%;
  gap: 2rem;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include screen-size('medium') {
    overflow-x: hidden;
  }
}

.item {
  display: grid;
  width: 100%;
  height: 100%;
  flex: 1 0 100%;
  padding: 1rem;
  scroll-snap-align: start;

  @include screen-size('extra-large') {
    place-content: center;
  }
}

.content {
  display: grid;

  img,
  span {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.spec-image {
    height: 14.3rem;
    margin-top: -1.5rem;

    @include screen-size('medium') {
      height: fit-content;
    }
  }
}
