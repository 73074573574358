@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.score {
  @include text-bold;

  position: absolute;
  top: 50%;
  font-size: 12.31px;
  line-height: 1.4;
  transform: translateY(-50%);
}

.ring {
  path {
    stroke-dasharray: 0, 100;
    transition: stroke-dasharray 2s cubic-bezier(0.3, 0.15, 0.34, 0.97),
    stroke 2s cubic-bezier(0.3, 0.15, 0.34, 0.97);
  }
}

.container {
  display: flex;
  align-items: flex-start;
  padding: 0.4375rem 1rem 0.4375rem 0.4375rem;
  border: 1px solid rgb(255 255 255 / 11%);
  border-radius: 100px;
  background: linear-gradient(79deg,
    rgb(255 255 255 / 70%) 0%,
    rgb(255 255 255 / 11%) 63.5%,
    rgb(241 241 241 / 0%) 100%),
  rgb(242 242 247 / 94%);
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 4%);
  gap: $spacing-xs;

  &[data-is-inline='true'] {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  &[data-icon-only='true'] {
    padding: 0;
    border: 0 none;
    border-radius: 50%;
    background: unset;
    box-shadow: unset;
  }
}

.text {
  display: grid;
  width: max-content;
  text-align: left;
}

.title,
.description {
  @include text-xs;

  margin: 0;
  color: $label-text;
}

.title {
  @include text-bold;
}

.description {
  @include text-normal;
}
